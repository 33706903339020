<template>
	<div class="quanjingCon">
		<div v-if="currentLandData['cloud'] == null">
			暂无数据
		</div>
		<div v-if="currentLandData['cloud']">
			<iframe class="outsideBox" :src="currentLandData['cloud']" frameborder="0"></iframe>
		</div>
	</div>
</template>

<script>


import { datadetail } from '@/api/data'
export default {
	name: 'FrontendPcBigdataXianzhuang',


	data() {
		return {
			id: 0,
			currentLandData:{}
		};
	},


	mounted() {
		if (this.$route.params.id) {
			this.id = this.$route.params.id
			datadetail({ id: this.id }).then(res => {
				this.currentLandData = res.data
			})
		}
	},

};
</script>

<style lang="scss" scoped>
.quanjingCon {
	// background:red;
	width: 98%;
	margin: 1%;
	height: calc(100vh - 200px);

	.outsideBox {
		width: 100%;

		height: calc(100vh - 200px);

	}
}
</style>